import React from "react";
import styled from "styled-components";
import { T } from "../../../styles/Theme";
import { ButtonLine } from "../../../styles/Buttons";

import img1 from "../../../images/blog/foto1.png";
import img2 from "../../../images/blog/foto2.png";
import img3 from "../../../images/blog/foto3.png";
import img4 from "../../../images/blog/foto4.png";

const BlogComponent = () => {
  return (
    <BlogStyled>
      <a className="anchor" id="blog"></a>
      <div className="subContainer">
        <Header>
          <h4>Blog</h4>
          <ButtonLine>Ver Todas</ButtonLine>
        </Header>

        <p className="description">
          Pellentesque lorem dolor, condimentum vel nisl at, pellentesque
          ultricies arcu. Vestibulum ornare, justo sed dictum commodo, purus
          neque.
        </p>

        <BlogContent>
          <div className="headerContainer">
            <div className="contentHeader"></div>
            <p>Sed pretium venenatis odio, et cursus risus posuere eget.</p>
          </div>

          <div className="miniContent">
            <div className="post">
              <img src={img2} alt="" />

              <div className="division">
                <h5>Nam ultricies, arcu facilisi commodo lobortis, ligula</h5>

                <p className="date">Marzo 10, 2021</p>

                <p>
                  Class aptent taciti sociosqu ad litora torquent per conubia
                  nostra, per inceptos himenaeos.
                </p>
              </div>
            </div>

            <div className="post">
              <img src={img3} alt="" />

              <div className="division">
                <h5>Nam ultricies, arcu facilisi commodo lobortis, ligula</h5>

                <p className="date">Marzo 10, 2021</p>

                <p>
                  Class aptent taciti sociosqu ad litora torquent per conubia
                  nostra, per inceptos himenaeos.
                </p>
              </div>
            </div>

            <div className="post">
              <img src={img4} alt="" />

              <div className="division">
                <h5>Nam ultricies, arcu facilisi commodo lobortis, ligula</h5>

                <p className="date">Marzo 10, 2021</p>

                <p>
                  Class aptent taciti sociosqu ad litora torquent per conubia
                  nostra, per inceptos himenaeos.
                </p>
              </div>
            </div>
          </div>
        </BlogContent>
      </div>
    </BlogStyled>
  );
};

export default BlogComponent;

const BlogContent = styled.div`
  a.anchor {
    display: block;
    position: relative;
    top: 0;
    visibility: hidden;
  }

  .post {
    padding-top: 45px;
    display: grid;
    grid-template-columns: 170px 1fr;
  }

  .division {
    h5 {
      font-size: 18px;
      color: #939393;
      margin: 0;
    }

    .date {
      color: ${T.orange};
    }

    p {
      font-size: 14px;
    }
  }

  .headerContainer {
    position: relative;
    margin: auto;
    min-width: 200px;
    width: 100%;

    p {
      color: white;
      font-size: 30px;
      font-weight: 600;
      line-height: 1.2;
      margin: 0;
      position: absolute;
      bottom: 20px;
      left: 15px;
    }
  }

  .contentHeader {
    height: 225px;
    width: 100%;
    background-image: url(${img1});
    background-size: cover;
    background-repeat: no-repeat;
    margin: auto;
    display: flex;
    align-items: flex-end;
    filter: brightness(70%);
  }

  @media (min-width: 760px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;

    .headerContainer {
      align-self: flex-start;
      margin: 0 auto;
      max-width: none;
    }
    .contentHeader {
      height: 620px;
    }
  }
`;

const Header = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  h4 {
    color: ${T.orange};
    font-size: 25px;
  }
`;

const BlogStyled = styled.section`
  background-color: #f8f8f8;
  padding: 60px 20px;
  margin-top: 130px;
  min-height: calc(100vh - 130px);

  .subContainer {
    max-width: ${T.maxWidth};
    margin: auto;

    .description {
      max-width: 500px;
      line-height: 2;
    }
  }

  p {
    font-size: 14px;
  }
`;
