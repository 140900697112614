import React from "react";
import { Layout } from "../components/Layout";
import BlogComponent from "../components/Sections/Blog";

const Blog = () => {
  return (
    <Layout>
      <BlogComponent />
    </Layout>
  );
};

export default Blog;
